// src/components/AdminStyles.js
import styled, { keyframes } from 'styled-components';

export const AdminContainer = styled.div`
  display: flex;
  background-color: rgba(30, 30, 30, 0); /* Transparent background */
  height: 100vh; /* Full viewport height */
  font-family: 'Roboto', sans-serif; /* Apply the custom font */
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  padding: 20px;
  overflow: hidden;
  position: relative;
  margin-left: 260px; /* Leave space for the sidebar */
  font-family: 'Roboto', sans-serif; /* Apply the custom font */
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(30, 30, 30, 0.90); /* Slightly transparent background */
  padding: 20px;
  width: calc(100% - 15px); /* Adjust width to leave space for sidebar */
  height: calc(100vh - 40px); /* Full height minus padding */
  border-radius: 15px; /* Rounded edges for the card container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: -15px; /* Adjust left margin to position properly */
  box-sizing: border-box; /* Include padding in width and height calculations */
  overflow-y: auto; /* Enable vertical scrolling */
`;

export const Title = styled.h1`
  color: #fff; /* Keep the title white */
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif; /* Apply the custom font */
`;

export const Description = styled.p`
  color: #ccc;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif; /* Apply the custom font */
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin-bottom: 20px;
`;

export const CardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%; /* Make sure the grid takes full width */
`;

export const Card = styled.div`
  background-color: #fff;
  border: 1px solid #ddd; /* Light grey border for straight edges */
  margin: 10px;
  padding: 20px;
  width: 250px; /* Adjusted width for card */
  height: 150px; /* Adjusted height for card */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  transition: transform 0.3s;
  color: #000; /* Black text color */

  &:hover {
    transform: scale(1.05);
  }
`;

export const CardTitle = styled.h2`
  background-color: #000; /* Black background for title */
  color: #fff; /* White text color */
  padding: 5px 10px;
  margin: -22px 0px 10px -25px; /* Negative left and right margins to cover the card's edges */
  width: calc(93%); /* Full width to cover the card's edges */
  font-family: 'Roboto', sans-serif; /* Apply the custom font */
  font-size: 12px;
  text-align: left; /* Align text to the left */
`;

export const CardDescription = styled.p`
  color: #333; /* Dark grey text color for description */
  font-family: 'Roboto', sans-serif; /* Apply the custom font */
  font-size: 12px;
  text-align: left; /* Align text to the left */
  margin-top: 5px; /* Add some space between the title and description */
  margin-left: -10px;
`;


export const FormTitle = styled.h2`
  margin-bottom: 20px;
`;


// Keyframes for flip animation
const flipIn = keyframes`
  0% {
    transform: rotateY(90deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(0);
    opacity: 1;
  }
`;

const flipOut = keyframes`
  0% {
    transform: rotateY(0);
    opacity: 1;
  }
  100% {
    transform: rotateY(90deg);
    opacity: 0;
  }
`;

export const CardFormContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  border-radius: 10px;
  padding: 20px 45px 30px 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 25%;
  left: 40%;
  transform: translate(-50%, -50%);
  z-index: 10000; /* Ensure the form has a very high z-index */
  animation: ${flipIn} 0.5s ease forwards;

  &.flipOut {
    animation: ${flipOut} 0.5s ease forwards;
  }

  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
  justify-content: center; /* Center the content vertically */
  width: 400px; /* Set a fixed width for the form */
  max-width: 90vw; /* Ensure the form is responsive */
  height: auto; /* Allow height to adjust based on content */
`;


export const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
`;

export const FormTextarea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%; /* Ensure buttons are correctly aligned */
`;

export const FormButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #555;
  }
`;
