import Dashboard from "./components/Dashboard";
import Admin from "./components/Admin";
import {
  faBell,
  faChartColumn, faGears,
  faInfo,
  faWrench
} from '@fortawesome/free-solid-svg-icons';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: faChartColumn,
    component: Dashboard, // Adjust according to your imports
    layout: '/home',
  },
  {
    path: '/admin',
    name: 'Admin',
    icon: faWrench,
    component: Admin, // Adjust according to your imports
    layout: '/admin',
  },
  {
    path: '/user_info',
    name: 'User Information',
    icon: faInfo,
    component: Dashboard, // Adjust according to your imports
    layout: '/home',
  },
  {
    path: '/configure',
    name: 'Configuration',
    icon: faGears,
    component: Dashboard, // Adjust according to your imports
    layout: '/home',
  },
  {
    path: '/notifications',
    name: 'Notifications',
    icon: faBell,
    component: Dashboard, // Adjust according to your imports
    layout: '/home',
  },
  // Add more routes as needed
];

export default routes;
