// src/components/Notification.js

import React from 'react';
import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const Container = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  min-width: 300px;
  padding: 16px;
  background-color: ${({ type }) => (type === 'success' ? 'green' : 'red')};
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: ${slideIn} 0.3s ease-out;
  z-index: 1000;
`;

const Message = styled.p`
  margin: 0;
  font-size: 16px;
`;

const Notification = ({ message, type }) => (
  <Container type={type}>
    <Message>{message}</Message>
  </Container>
);

export default Notification;
