// src/card_form_configs.js

const cardFormConfigs = {
  'Insert New Tickers to DB': {
    route: '/insert_tickers',
    fields: [
      { type: 'text', name: 'tickers', placeholder: 'Tickers List' },
      { type: 'text', name: 'innerTimeframe', placeholder: 'Inner Timeframe' },
      { type: 'text', name: 'outerTimeframe', placeholder: 'Outer Timeframe' }
    ]
  },
  'Set Tickers as offline': {
    route: '/set_tickers_offline',
    fields: [
      { type: 'text', name: 'tickers', placeholder: 'Tickers List' }
    ]
  },
  'Re-stream tickers history data': {
    route: '/re_stream_tickers',
    fields: [
      { type: 'text', name: 'tickers', placeholder: 'Tickers List' }
    ]
  },
  'Restart Guardfield Services (Caution)': {
    route: '/restart_services',
    fields: [
      { type: 'text', name: 'confirmation', placeholder: 'Type "CONFIRM" to proceed' }
    ]
  }
};

export default cardFormConfigs;
