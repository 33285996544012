import React from 'react';
import { Container, Title, Description, ButtonContainer, StyledLink, SubTitle } from './LandingPageStyles';

const LandingPage = () => {
  return (
      <Container>
        <SubTitle>WELCOME TO:</SubTitle>
        <Title>GUARDFIELD</Title>
        <Description>``BEST ALGORITHMIC STOCK TRADING PLATFORM THERE IS.`` ~ Warren Buffet</Description>
        <ButtonContainer>
          <StyledLink to="/login">
            <button>SIGN IN</button>
          </StyledLink>
        </ButtonContainer>
      </Container>
  );
};

export default LandingPage;
