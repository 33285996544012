// src/components/Admin.js
import React, { useState } from 'react';
import { AdminContainer, ContentContainer, CardContainer, CardGrid, Card, CardTitle, CardDescription, Title, Separator, Description } from './AdminStyles';
import cards from '../admin_panel_cards';
import cardFormConfigs from '../card_form_configs';
import CardForm from './CardForm';
import Sidebar from './Sidebar';
import routes from '../routes'; // Import the routes for Sidebar
import Notification from './Notification'; // Assuming you have a notification component
import apiClient from '../utils/api'; // Import the apiClient

const Admin = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [notification, setNotification] = useState(null);

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleCloseForm = () => {
    setSelectedCard(null);
  };

  const handleFormSubmit = async (formData) => {
    const config = cardFormConfigs[selectedCard.title];
    if (config) {
      try {
        const response = await apiClient.post(config.route, formData);

        if (response.status === 200) {
          setNotification({ type: 'success', message: 'Form submitted successfully!' });
        } else {
          setNotification({ type: 'error', message: 'Failed to submit form.' });
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        setNotification({ type: 'error', message: 'Error occurred while submitting form.' });
      }

      // Remove the notification after a delay
      setTimeout(() => setNotification(null), 2000);

      // Close the form after submission
      handleCloseForm();
    }
  };

  return (
    <AdminContainer>
      <Sidebar routes={routes} /> {/* Add the Sidebar component */}
      <ContentContainer>
        <CardContainer>
          <Title>Admin Dashboard</Title>
          <Description>This is the admin dashboard where you can manage your settings.</Description>
          <Separator />
          <CardGrid>
            {cards.map((card) => (
              <Card key={card.id} onClick={() => handleCardClick(card)}>
                <CardTitle>{card.title}</CardTitle>
                <CardDescription>{card.description.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}</CardDescription>
              </Card>
            ))}
          </CardGrid>
          {selectedCard && (
            <CardForm
              title={selectedCard.title}
              description={selectedCard.description}
              formFields={cardFormConfigs[selectedCard.title]?.fields || []}
              onSubmit={handleFormSubmit}
              onClose={handleCloseForm}
            />
          )}
          {notification && <Notification type={notification.type} message={notification.message} />}
        </CardContainer>
      </ContentContainer>
    </AdminContainer>
  );
};

export default Admin;
