// src/components/StreamlitEmbed.js

import React from 'react';
import config from '../utils/config';

const StreamlitEmbed = () => {
  return (
    <iframe
      src={config.STREAMLIT_URL}
      width="100%"
      height="1000px"
      frameBorder="0"
    ></iframe>
  );
};

export default StreamlitEmbed;