// src/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';
import './assets/fonts/fonts.css'; // Adjust the path as necessary

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Nucleo', sans-serif;
  }
`;

export default GlobalStyle;
