// src/pages/LoginPageStyles.js
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgba(18, 18, 18, 0);
    color: rgba(245, 245, 245, 0);
    font-family: 'Roboto', sans-serif;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(30, 30, 30, 0.65);
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

export const Title = styled.h1`
    font-size: 2rem; /* Increased font size */
    color: #eadddd;
    font-family: 'Roboto Slab', sans-serif; /* Updated font */
    margin-bottom: 1.5rem;
`;

export const Input = styled.input`
    padding: 0.5rem;
    margin: 0.5rem 0;
    width: 100%;
    border: 1px solid #555;
    border-radius: 5px;
    font-size: 1rem;
    background-color: rgba(46, 46, 46, 0.75);
    color: #f5f5f5;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export const Button = styled.button`
  padding: 0.75rem 1.5rem;
  margin-top: 1rem;
  font-size: 1rem;
  background-color: rgba(35, 35, 35, 0.4);
  color: #eadddd;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: rgba(35, 35, 35, 0.75);
    transform: scale(1.05);
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
