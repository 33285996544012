import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import GlobalStyle from './GlobalStyles'; // Adjust the path as necessary
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import Layout from './Layout';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Admin from "./components/Admin";

function App() {
  return (
    <AuthProvider>
      <Layout>
      <GlobalStyle />
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/dashboard" element={<PrivateRoute element={HomePage} />} />
            <Route path="/admin" element={<PrivateRoute element={Admin} />} />
          </Routes>
        </Router>
      </Layout>
    </AuthProvider>
  );
}

export default App;
