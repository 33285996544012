// src/components/CardForm.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CardFormContainer, FormTitle, FormInput, FormTextarea, ButtonContainer, FormButton } from './AdminStyles';

const CardForm = ({ title, description, formFields, onSubmit, onClose }) => {
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <CardFormContainer>
      <FormTitle>{title}</FormTitle>
      <p style={{ color: '#ccc' }}>{description}</p>
      <form onSubmit={handleSubmit}>
        {formFields.map((field) => (
          <div key={field.name}>
            {field.type === 'textarea' ? (
              <FormTextarea
                name={field.name}
                placeholder={field.placeholder}
                onChange={handleChange}
                required={field.required}
              />
            ) : (
              <FormInput
                type={field.type}
                name={field.name}
                placeholder={field.placeholder}
                onChange={handleChange}
                required={field.required}
              />
            )}
          </div>
        ))}
        <ButtonContainer>
          <FormButton type="submit">Submit</FormButton>
          <FormButton type="button" onClick={onClose}>Close</FormButton>
        </ButtonContainer>
      </form>
    </CardFormContainer>
  );
};

CardForm.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      required: PropTypes.bool,
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CardForm;
