import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";
import { Nav } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import {
  SidebarContainer,
  SidebarTitle,
  TitleLine,
  SidebarDivider,
  NavItem,
  StyledNavLink,
} from "./SidebarStyles"; // Adjust the path as per your project structure

var ps;

function Sidebar({ routes }) {
  const location = useLocation();
  const sidebarRef = React.useRef(null);

  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  return (
    <SidebarContainer ref={sidebarRef}>
      <SidebarTitle>
        <TitleLine />
        <TitleLine />
        <TitleLine />
      </SidebarTitle>
      <SidebarDivider />
      <Nav>
        {routes.map((prop, key) => {
          if (prop.redirect) return null;
          return (
            <NavItem key={key} className={activeRoute(prop.path)}>
              <StyledNavLink to={prop.path} className="nav-link">
                <FontAwesomeIcon icon={prop.icon} style={{ marginRight: 20 }} />
                <p>{prop.name}</p>
              </StyledNavLink>
            </NavItem>
          );
        })}
      </Nav>
    </SidebarContainer>
  );
}

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default Sidebar;
