// src/pages/HomePageStyles.js

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: rgba(30, 30, 30, 0); /* Transparent background */
  height: 100vh; /* Full viewport height */
  font-family: 'Roboto', sans-serif; /* Apply the custom font */
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  padding: 20px;
  overflow: hidden;
  position: relative;
  margin-left: 260px; /* Leave space for the sidebar */
  font-family: 'Roboto', sans-serif; /* Apply the custom font */
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(30, 30, 30, 0.90); /* Slightly transparent background */
  padding: 20px;
  width: calc(100% - 15px); /* Adjust width to leave space for sidebar */
  height: calc(100vh - 40px); /* Full height minus padding */
  border-radius: 15px; /* Rounded edges for the card container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: -15px; /* Adjust left margin to position properly */
  box-sizing: border-box; /* Include padding in width and height calculations */
  overflow-y: auto; /* Enable vertical scrolling */
`;


export const Nav = styled.nav`
    width: 0px;
    background-color: rgba(30, 30, 30, 0.85);
    color: #fff;
`;

export const Content = styled.div`
  flex: 1;
  background-color: rgba(30, 30, 30, 0.85);
  padding: 20px;
`;
