// src/admin_panel_cards.js

const cards = [
  {
    id: 1,
    title: 'Insert New Tickers to DB',
    description: 'Insert new tickers to the DB. \nRequires: \n1. Tickers List Seperated by commas\n2. Inner Timeframe (1h/1d/1m)\n3. Outer Timeframe (1h/1d/1m)\n',
  },
  {
    id: 2,
    title: 'Set Tickers as offline',
    description: 'Set tickers that we shouldn\'t run on as offline.\nRequires:\n Tickers List Seperated by commas',
  },
  {
    id: 3,
    title: 'Re-stream tickers history data',
    description: 'Re-streams ticker history data into DB in case we are missing some candles.\nRequires:\n Tickers List Seperated by commas',
  },
  {
    id: 4,
    title: 'Restart Guardfield Services (Caution)',
    description: 'Restarts the Guardfield services in order to reload new settings',
  },
];

export default cards;
