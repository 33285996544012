import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgba(18, 18, 18, 0);
    color: rgba(245, 245, 245, 0);
    font-family: 'Roboto', sans-serif;
`;

export const Title = styled.h1`
    font-size: 3rem;
    color: #eadddd;
    font-family: 'Roboto Slab', sans-serif;
    margin: 0;  /* Remove margin */
`;

export const SubTitle = styled.h1`
    font-size: 2rem;
    color: #eadddd;
    font-family: 'Roboto Slab', sans-serif;
    margin: 0;  /* Remove margin */
`;

export const Description = styled.p`
  font-size: 1.2rem;
  color: #eadddd;
  font-family: 'Roboto Slab', sans-serif;
  margin-top: 0.8rem; /* Adjust margin-top */
  margin-bottom: 0;
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;
`;

export const StyledLink = styled(Link)`
    text-decoration: none;

    button {
        padding: 1rem 2rem;
        font-size: 0.8rem;
        background-color: rgba(35, 35, 35, 0.4);
        color: #eadddd;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s, transform 0.3s;

        &:hover {
            background-color: rgba(35, 35, 35, 0.75);
            transform: scale(1.05);
        }
    }
`;
