import styled from 'styled-components';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SidebarContainer = styled.div`
  position: fixed;
  top: 20px;
  bottom: 20px;
  left: 20px;
  z-index: 1032;
  overflow: hidden;
  width: 240px;
  background-color: rgba(30, 30, 30, 0.96);
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Nucleo', sans-serif; /* Apply the custom font */
`;

export const SidebarTitle = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const TitleLine = styled.div`
  width: 20%;
  height: 3px;
  background-color: #545454;
  margin: 8px auto;
`;

export const SidebarDivider = styled.div`
  width: 75%;
  margin: 30px auto 10px auto;
  border-top: 1px solid #b2b2b2;
  padding-top: 8px;
`;

export const Nav = styled.ul`
  margin-top: 20px;
  padding: 0;
  list-style: none;
`;

export const NavItem = styled.li`
  margin: 10px 0;
  list-style-type: none;
`;

export const StyledNavLink = styled(ReactRouterNavLink)`
  display: flex;
  align-items: center;
  border-radius: 3px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  font-family: 'Nucleo', sans-serif; /* Apply the custom font */
  padding: 10px 15px;
  margin-right: 10px; /* Right margin to prevent hover effect reaching the end */
  margin-left: -25px; /* Add margin to the left for more balanced hover effect */
  transition: background-color 0.3s ease; /* Smooth transition for background color */

  &:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Lighter background on hover */
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 10px; /* Adjust spacing between icon and text */
`;
