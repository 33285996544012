import React from 'react';
import {Container, Nav, Content, ContentContainer, DashboardContainer} from './HomePageStyles';
import Sidebar from '../components/Sidebar';
import Dashboard from '../components/Dashboard';
import routes from '../routes'; // Ensure routes are imported correctly

const HomePage = () => {
  return (
    <Container>
    <Sidebar routes={routes} />
    <ContentContainer>
        <DashboardContainer>
           <Dashboard />
        </DashboardContainer>
    </ContentContainer>
    </Container>
  );
};

export default HomePage;
