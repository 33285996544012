// src/pages/LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Title, Input, Button, StyledLink, ButtonContainer } from './LoginPageStyles';
import { login as apiLogin } from '../utils/api'; // Import your login function
import Notification from '../components/Notification'; // Import your Notification component
import { useAuth } from '../context/AuthContext'; // Import the useAuth hook

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [notification, setNotification] = useState(null);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await apiLogin(username, password);
      login(); // Update the authentication state
      setNotification({ type: 'success', message: 'Login successful!' });
      setTimeout(() => {
        setNotification(null);
        navigate('/dashboard'); // Navigate to the home page on successful login
      }, 800); // Delay to show notification
    } catch (error) {
      setNotification({ type: 'error', message: 'Login failed. Please try again.' });
    }
  };

  return (
    <Container>
      <Form onSubmit={handleLogin}>
        <Title>SIGN IN</Title>
        <Input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
        <Input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <ButtonContainer>
          <Button type="submit">Login</Button>
          <StyledLink to="/">
            <Button>Back</Button>
          </StyledLink>
        </ButtonContainer>
      </Form>
      {notification && (
        <Notification type={notification.type} message={notification.message} />
      )}
    </Container>
  );
};

export default LoginPage;
